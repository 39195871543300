<template>
  <header class="navbar">
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        to="/"
      >
        <img
          class="logo"
          src="@/assets/asiaibs-logo.png"
          alt="AsiaIBS"
        >
      </router-link>

      <div
        class="navbar-burger burger"
        data-target="navMenu"
        @click="toggleSidebar"
      >
        <span/>
        <span/>
        <span/>
      </div>
    </div>

    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': sidebar }"
    >
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is__flex">
            {{ authentication.name }}
          </a>

          <div class="navbar-dropdown is-right">
            <a class="navbar-item" href="/change-password">
              <i class="material-icons">enhanced_encryption</i>
              Cambiar Contraseña
            </a>
            <a
              class="navbar-item"
              @click="signOut"
            >
              <i class="material-icons">exit_to_app</i>
              Cerrar Sesión
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TheHeader',
  computed: {
    ...mapState({
      authentication: state => state.authentication,
      sidebar: state => state.ui.sidebar
    })
  },
  methods: { ...mapMutations(['toggleSidebar', 'signOut']) }
}
</script>

<style lang="scss" scoped>
  .navbar {
    background-color: var(--primary-blue);
    min-height: 90px;
    padding: 0 0 0 1rem;
  }

  img.logo {
    max-width: 80px;
    object-fit: cover;
  }

  a {
    font-size: 16px;
    color: white;
  }

  .is__flex {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .navbar-link:not(.is-arrowless) {
    padding-right: 3rem;
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: white;
  }

  .toggle-menu {
    cursor: pointer;
  }

  .navbar-menu .navbar-dropdown .navbar-item {
    display: flex;
    align-items: center;
  }

  .navbar-item img {
    max-height: 70px;
  }

  .navbar-menu .navbar-dropdown .navbar-item i {
    padding-right: 4px;
  }

  .navbar-burger {
    height: inherit;
  }

  .profile-image {
    object-fit: cover;
    width: 48px;
    height: 48px;
  }

  .navbar-dropdown {
    background-color: var(--primary-blue);
    border-top: 2px solid var(--primary-blue);
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
  }

  a.navbar-item,
  .navbar-link,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: transparent !important;
    color: white;
  }

  .navbar-dropdown.is-right {
    left: auto;
    right: -1rem;
  }

  @media screen and (max-width: 1023px) {
    .navbar {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 1.5rem;
    }
    .navbar-burger {
      color: white;
    }
    .navbar-menu {
      background-color: transparent;
    }
    .navbar-dropdown {
      box-shadow: none;
    }
    .navbar-link:not(.is-arrowless)::after {
      display: none;
    }
    .navbar-link:focus-within:not(.is-arrowless) {
      padding: 0.5rem 0.75rem;
    }
    .navbar-dropdown .navbar-item, .navbar-link {
      padding: 0.5rem 0.75rem;
    }
  }
</style>
